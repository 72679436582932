<template>
  <VanDialog
    :value="value"
    :close-on-click-overlay="true"
    title="客服"
    @click-overlay="close"
    :before-close="submit"
    @cancel="close"
  >
    <div class="pt20 plr20 pb20 ">
      <div class="tc fs16 fc6 mb20 zm-flex is-center">
        <span class="mr10">请添加QQ:3221893641</span>
        <van-button type="info" size="small" v-clipboard:copy="3221893641"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError">复制</van-button>
      </div>
    </div>
  </VanDialog>
</template>
<script>
import apiUser from '@/api/src/apiUser';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: ''
    };
  },
  computed: {
  },
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit('input', false);
    },
    onCopy(e) {
      this.$toast('复制成功!' + e.text);
    },
    onError: function (e) {
      alert('Failed to copy texts');
    },
    async submit() {
      this.close();
    }
  }
};
</script>
<style lang="scss" scoped>
    .input-box{
        border:1px solid #eee;
        border-radius: .1rem;
    }
</style>