<template>
  <div class="mine">
    <div class="top tc is-bgPrimary">
      <van-image
        :lazy-load="false"
        round
        width="2rem"
        height="2rem"
        :src="(userInfo && userInfo.photo) | defaultSrc"
        class="vm"
      ></van-image>
      <div v-if="token">
        <div>用户名:{{ userInfo && (userInfo.name || userInfo.code) }}</div>
        <div>ID:{{ userInfo && userInfo.code }}</div>
      </div>
      <div v-else>
        <a href="javascript:;" @click="onLogin" class="color-white">点击登录</a>
      </div>
    </div>
    <!-- <div class="free-time df-center" v-if="freeTime">
      <zm-icon icon-class="time" class="mr5"></zm-icon>
      <div class="fc-white fs10">
        <span>免费结束时间：</span>
        <span>{{ freeTime }}</span>
      </div>
    </div> -->
    <van-cell-group class="mt20">
      <van-cell
        :title="item.title"
        is-link
        class="df-center cell-left"
        v-for="(item, index) in model"
        :key="index"
        @click="cellClick(item)"
      >
        <zm-icon
          :icon-class="item.icon"
          slot="icon"
          class="mine-icon"
          :style="{ color: item.iconColor }"
          v-if="item.icon.indexOf('zm') > -1"
        ></zm-icon>
        <van-icon slot="icon" class="mine-icon" :style="{ color: item.iconColor }" :name="item.icon" v-else />
      </van-cell>
    </van-cell-group>
    <advise v-model="showAdvise" v-if="showAdvise"></advise>
    <!-- <policy v-model="showPolicy" v-if="showPolicy" @cancel="onPolicyReject"></policy> -->
    <user-agree
      v-model="showPolicy"
      title="隐私协议"
      @cancel="onPolicyReject"
      :show-confirm="true"
    />
    <bind v-model="showBind" v-if="showBind" @after="afterBind"></bind>
    <UpdatePwd v-model="showUpPwd" v-if="showUpPwd"></UpdatePwd>
    <customerService v-model="showCustomer" v-if="showCustomer"></customerService>
    <app-share v-model="showShare" :shares="shares"></app-share>
    <h5-share v-model="showH5Share"></h5-share>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Advise from './components/advise';
// import Policy from './components/policy.vue';
import { alertConfirm } from '@/utils/src/utilsAlert';
import UpdatePwd from './components/updatePwd';
import customerService from './components/customerService';
import userAgree from '@/pages/login/components/userAgree';
import bind from '@/pages/index/components/Mine/components/bind';
import appShare from '@/pages/index/components/Mine/components/appShare';
import h5Share from '@/pages/index/components/Mine/components/h5Share';
import { appUnLoginHandler } from '@/utils/src/utilsApp';
import config from '@/config';
import * as tools from '@/utils/src/tools';
import * as auth from '@/utils/auth';
import userApi from '@/api/src/apiUser';
import dayjs from 'dayjs';
const bindCell = {
  title: '绑定账号',
  funcName: 'onBind',
  isShowBind: 1,
  icon: 'manager',
  iconColor: '#c300ff'
};
const shareCell = {
  title: '分享好友',
  funcName: 'onShare',
  icon: 'share',
  iconColor: '#67C23A'
};
const downloadApk = {
  title: '下载app',
  funcName: 'onDownload',
  icon: 'zmdownload',
  iconColor: '#67C23A'
};
const follow = {
  title: '关注公众号',
  funcName: 'onFollow',
  icon: 'zmfollow',
  iconColor: '#67C23A'
};
const model = () => {
  return [
    {
      title: '修改密码',
      funcName: 'onUpdate',
      icon: 'lock',
      isShowLogin: 1,
      iconColor: '#f4ea2a'
    },
    {
      title: '改进意见',
      funcName: 'onAdvice',
      icon: 'friends',
      iconColor: '#E6A23C'
    },
    {
      title: '联系我们',
      funcName: 'onContact',
      icon: 'phone',
      iconColor: 'primary'
    },
    {
      title: '隐私政策',
      funcName: 'onPolicy',
      icon: 'question',
      iconColor: '#0D91FF'
    },
    {
      title: '退出登录',
      funcName: 'onLogout',
      isShowLogin: 1,
      icon: 'zmlogout',
      iconColor: '#F56C6C'
    },
    {
      title: '注销账户',
      funcName: 'onDestroyAccount',
      isShowLogin: 1,
      icon: 'clear',
      iconColor: '#F56C6C'
    }
  ];
};
export default {
  name: 'Mine',
  components: { Advise, userAgree, UpdatePwd, customerService, bind, appShare, h5Share },
  data() {
    return {
      // userInfo: {},
      showPolicy: false,
      showAdvise: false,
      showShare: false,
      showH5Share: false,
      showBind: false,
      showUpPwd: false,
      showCustomer: false,
      shares: {}
    };
  },
  computed: {
    ...mapGetters(['token', 'userInfo']),
    model() {
      let _model = model(this);
      let res = _model.filter(item => {
        return this.token ? item : !item.isShowLogin;
      });
      if (!this.userInfo.account && this.token) {
        res.unshift(bindCell);
      }
      res.push(shareCell);
      if (tools.os.isWechat) {
        res.push(follow);
      }
      if (tools.os.isWechat) {
        res.push(downloadApk);
      }
      return res;
    }
  },
  watch: {},
  created() {
    let vm = this;
    if (window.plus && auth.isAppTest()) {
      window.plus.share.getServices(
        function(s) {
          vm.shares = {};
          for (var i in s) {
            var t = s[i];
            vm.shares[t.id] = t;
          }
          console.log('app获取分享服务列表成功');
        },
        function(e) {
          console.error('app获取分享服务列表失败：' + e.message);
        }
      );
    }
  },
  methods: {
    ...mapActions('app', ['appUpdateUserInfo','destroyAccount']),

    cellClick(item) {
      this[item.funcName]();
    },
    onLogin() {
      this.$router.push({ path: '/login', query: { redirect: this.$router.currentRoute.fullPath } });
    },
    onBind() {
      this.showBind = true;
    },
    onShare() {
      if (tools.os.isWechat) {
        this.showH5Share = true;
      } else if (window.plus) {
        this.showShare = true;
      } else {
        // this.showH5Share = true;
        this.$msg.alert({
          message: `请在微信或者app中分享 `
        });
      }
    },
    onAdvice() {
      this.showAdvise = true;
    },
    onUpdate() {
      this.showUpPwd = true;
    },
    onLogout() {
      alertConfirm({
        title: '友情提示',
        message: '确认退出登录？'
      }).then(() => {
        appUnLoginHandler();
        this.onLogin();
      });
    },
    onDestroyAccount() {
       alertConfirm({
        title: '友情提示',
        message: '注销后，账户将无法登录，如需重新登录请联系客服!'
      }).then(async () => {
        await userApi.cancellation();
        this.destroyAccount();
        this.$msg.showMsgSuccess('注销成功!');
      });
    },
    onContact() {
      this.showCustomer = true;
    },
    onPolicy() {
      this.showPolicy = true;
    },
    onPolicyReject() {
      this.$dialog.alert({
        message: '未同意隐私协议只能使用基础功能哦!'
      });
    },
    afterBind(email) {
      this.$msg.showMsgSuccess('绑定成功!');
    },
    onFollow() {
      window.location.href = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${config.uniBase}#wechat_redirect`;
    },
    onDownload() {
      this.$router.push('/downLoad');
      // window.location.href = appInfo.apkUrl || '/';
    }
  }
};
</script>
<style lang="scss">
a.color-white {
  color: $--color-white;
}
.mine {
  &-icon {
    font-size: 0.36rem;
    margin-right: 0.1rem;
  }
  .free-time {
    // position: absolute;
    background: red;
    color: #fff;
    left: 0;
    top: 0;
    padding: 5px 15px 5px;
    justify-content: center;
  }
  .top {
    padding: 0.3rem;
    background: $--color-white;
    position: relative;
    .free-time {
      // position: absolute;
      background: red;
      left: 0;
      top: 0;
      padding: 5px 15px 5px;
      border-radius: 0 12px 12px 0;
    }
    @include when(bgPrimary) {
      background: $--primary-gradient-background;
      color: #fff;
    }
  }
}
</style>
