<template>
  <VanDialog
    :value="value"
    show-cancel-button
    :close-on-click-overlay="true"
    @click-overlay="close"
    :before-close="submit"
    @cancel="close"
  >
    <div class="pt20 plr20 pb20 ">
      <div class="tc fs16 fc6 mb20">
        您宝贵的建议是我们前进的动力！
      </div>
      <div class="input-box">
      <van-field type="textarea" label="" t v-model.trim="content" placeholder="请输入建议" />
      </div>
    </div>
  </VanDialog>
</template>
<script>
import apiUser from '@/api/src/apiUser';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: ''
    };
  },
  computed: {
  },
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit('input', false);
    },
    async submit(action, done) {
      if (action === 'confirm') {
        if (!this.content) return this.$msg.showMsgFail('请输入您的建议');
          apiUser.feedback({content: this.content})
            .then(() => {
              this.$emit('after');
              this.close();
              done(true);
              this.$msg.showMsgSuccess('提交成功');
            })
            .catch((_) => {
              done(false);
            });
        }
    }
  }
};
</script>
<style lang="scss" scoped>
    .input-box{
        border:1px solid #eee;
        border-radius: .1rem;
    }
</style>