<template>
  <VanDialog
    :value="value"
    show-cancel-button
    title="绑定账号"
    :close-on-click-overlay="true"
    @click-overlay="close"
    :before-close="submit"
    @cancel="close"
  >
    <div class="pt20 plr20 pb20 ">
      <div class="input-box">
        <van-field label="邮箱" clearable t v-model.trim="email" placeholder="请输入邮箱" />
        <van-field label="密码" clearable type="password" v-model.trim="password" placeholder="请输入密码" />
        <van-field label="确认密码" clearable type="password" v-model.trim="newPwd" placeholder="请再次确认密码" />
      </div>
    </div>
  </VanDialog>
</template>
<script>
import apiUser from '@/api/src/apiUser';
import * as auth from '@/utils/auth';
import {mapActions} from 'vuex';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: '',
      active: '1',
      password: '',
      newPwd: ''
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions('app',['appUpdateUserInfo']),
    close() {
      this.$emit('input', false);
    },
    async submit(action, done) {
      console.log(action);
      if (action === 'confirm') {
        if (!this.password) {
          this.$msg.showMsg('请输入新密码!');
          return done(false);
        }
        if (this.password !== this.newPwd) {
          this.$msg.showMsg('两次密码不一样');
          return done(false);
        }
        let appInfo = auth.loc.get('appInfo', {});
        const params = {
          account: this.email,
          appInfoId: appInfo.id,
          imei: auth.getImei(),
          password: this.password
        };
        apiUser
          .userAccount({ ...params })
          .then((res) => {
            this.appUpdateUserInfo(res);
            this.$emit('after', this.email);
            done();
            this.close();
          })
          .catch(_ => {
            done(false);
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
